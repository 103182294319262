import * as Request from "../Request";

export interface Result<T> {
    status: number,
    message: string,
    data: T,
}
export interface HandheldDevices {
    hsn_id: number,
    ha_id: number,
    hsn_name: string,
    hsn_key: string
}
export interface ResultTipHandheld {
    total: number,
    tip: number
}

export const gListHandheldDevicesByOwnerId = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gListHandheldDevicesByOwnerId.php`, "POST", { owner_id })
        .then((res: Result<HandheldDevices[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};

export const connectCardPoint = async (owner_id: number, hsn_id: number) => {
    return await Request.fetchAPI(`owner/connectCardPoint.php`, "POST", { owner_id, hsn_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};
export const handleTipCardPoint = async (owner_id: number, hsn_id: number, SessionKey: string, amount: number) => {
    return await Request.fetchAPI(`owner/handleTipCardPoint.php`, "POST", { owner_id, hsn_id, SessionKey, amount })
        .then((res: Result<ResultTipHandheld>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: {
                    total: 0,
                    tip: 0
                }
            };
        });
};
export const disConnectCardPoint = async (owner_id: number, hsn_id: number, sessionKey: string) => {
    return await Request.fetchAPI(`owner/disconnectCardPoint.php`, "POST", { owner_id, sessionKey, hsn_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};
export const paymentWithCardPoint = async (owner_id: number, SessionKey: string, order_id: number, amount: number, hsn_id: number, typeMethod: string = 'authCard') => {
    return await Request.fetchAPI(`owner/paymentWithCardByHsn.php`, "POST", { owner_id, SessionKey, order_id, amount, hsn_id, typeMethod })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};